import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getApiUrlForPayroll, getLicenseKey } from "./application.settings";

const api = service()

export const getAcademicPeriod = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAcademicPeriod/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAcademicPeriod - " + error.message)
            console.log('error caught in service : getAcademicPeriod')
        },
    );

export const getDepartmentList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDepartment/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDepartment - " + error.message)
            console.log('error caught in service : getDepartment')
        },
    );

export const addDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addDepartment')
        }
    );


export const addDepartmentForPayroll = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `addDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addDepartment')
        }
    );

export const updateDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateDepartment')
        }
    );

    export const updateDepartmentForPayroll = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `updateDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateDepartment')
        }
    );

export const deleteDepartment = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteDepartment')
        }
    );

    export const deleteDepartmentForPayroll = (departmentPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `deleteDepartment`, departmentPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteDepartment')
        }
    );


export const getCourse = (departmentId: number, graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourse/${getLicenseKey}/${departmentId}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourse - " + error.message)
            console.log('error caught in service : getCourse')
        },
    );

export const getBatchByCourseId = (courseId: number, graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBatchByCourseId/${getLicenseKey}/${courseId}/0/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getBatchByCourseId - " + error.message)
            console.log('error caught in service : getBatchByCourseId')
        },
    );

export const getSemester = (batchMasterId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSemester/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getSemester - " + error.message)
            console.log('error caught in service : getSemester')
        },
    );

export const getCourseTypeByCourseTypeId = (courseTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseTypeByCourseTypeId/${getLicenseKey}/${courseTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseTypeByCourseTypeId - " + error.message)
            console.log('error caught in service : getCourseTypeByCourseTypeId')
        },
    );

export const getBatchByGraduationTypeId = (courseId: number, batchMasterId: number, graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBatchByCourseId/${getLicenseKey}/${courseId}/${batchMasterId}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getBatchByCourseId - " + error.message)
            console.log('error caught in service : getBatchByCourseId')
        },
    );
export const addAcademicPeriod = (academicPeriodPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addAcademicPeriod`, academicPeriodPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addAcademicPeriod')
        }
    );

export const updateAcademicPeriod = (academicPeriodPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateAcademicPeriod`, academicPeriodPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateAcademicPeriod')
        }
    );

export const deleteAcademicPeriod = (academicPeriodPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteAcademicPeriod`, academicPeriodPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteAcademicPeriod')
        }
    );
export const getCourseType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseType - " + error.message)
            console.log('error caught in service : getCourseType')
        },
    );

export const getBatchMasterDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBatchMasterDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getBatchMasterDetails - " + error.message)
            console.log('error caught in service : getBatchMasterDetails')
        },
    );
export const addBatchMasterDetails = (batchMasterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addBatchMasterDetails`, batchMasterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addBatchMasterDetails')
        }
    );
export const updateBatchMaster = (batchAndSemesterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateBatchMaster`, batchAndSemesterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateBatchMaster')
        }
    );
export const deleteBatchMaster = (batchAndSemesterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteBatchMaster`, batchAndSemesterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteBatchMaster')
        }
    );
export const addSemesterDetails = (semesterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addSemesterDetails`, semesterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addSemesterDetails')
        }
    );
export const updateSemester = (updateSemesterPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateSemesterDetails`, updateSemesterPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateSemesterDetails')
        }
    );
export const getCourseDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourse/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getCourse - " + error.message)
            console.log('error caught in service : getCourse')
        },
    );
export const getDegreeType = (graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDegreeType/${getLicenseKey}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getDegreeType - " + error.message)
            console.log('error caught in service : getDegreeType')
        },
    );
export const getYearByCourseTypeId = (courseTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getYearByCourseTypeId/${getLicenseKey}/${courseTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getYearByCourseTypeId - " + error.message)
            console.log('error caught in service : getYearByCourseTypeId')
        },
    );
export const addCourse = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCourse`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCourse')
        }
    );
export const updateCourse = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateCourse`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCourse')
        }
    );
export const deleteCourse = (coursePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteCourse`, coursePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCourse')
        }
    );

export const getCasteDetails = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCasteDetails/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getCasteDetails - " + error.message)
            console.log('error caught in service : getCasteDetails')
        },
    );
export const addCasteDetails = (castePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCasteDetails`, castePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCasteDetails')
        }
    );
export const updateCasteDetails = (castePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateCasteDetails`, castePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCasteDetails')
        }
    );
export const deleteCasteDetails = (castePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteCasteDetails`, castePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteCasteDetails')
        }
    );
export const getBatchByCourse = (graduationTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getBatchByCourseId/${getLicenseKey}/0/0/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getBatchByCourseId - " + error.message)
            console.log('error caught in service : getBatchByCourseId')
        },
    );

export const getCourseByBatchMasterId = (batchMasterId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseByBatchMasterId/${getLicenseKey}/${batchMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseByBatchMasterId - " + error.message)
            console.log('error caught in service : getCourseByBatchMasterId')
        },
    );
export const getCourseCapacityByBatchMasterId = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseCapacityByBatchMasterId/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseCapacityByBatchMasterId - " + error.message)
            console.log('error caught in service : getCourseCapacityByBatchMasterId')
        },
    );
export const addCourseCapacity = (courseCapacityPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addCourseCapacity`, courseCapacityPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addCourseCapacity')
        }
    );
export const updateCourseCapacity = (courseCapacityEditPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateCourseCapacity`, courseCapacityEditPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateCourseCapacity')
        }
    );
export const getSemesterType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getSemesterType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getSemesterType - " + error.message)
            console.log('error caught in service : getSemesterType')
        },
    );
export const getMonthBySemesterTypeId = (semesterTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getMonthBySemesterTypeId/${getLicenseKey}/${semesterTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getMonthBySemesterTypeId - " + error.message)
            console.log('error caught in service : getMonthBySemesterTypeId')
        },
    );
export const getDayList = (academicPeriodId: string, semesterTypeId: string, monthId: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDayList/${getLicenseKey}/${academicPeriodId}/${semesterTypeId}/${monthId}`,
        response => {
            success(response)
        },
        error => {
            failure("getDayList - " + error.message)
            console.log('error caught in service : getDayList')
        },
    );
export const getDayOrderType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDayOrderType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getDayOrderType - " + error.message)
            console.log('error caught in service : getDayOrderType')
        },
    );
export const addDayOrder = (dayOrderPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addDayOrder`, dayOrderPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addDayOrder')
        }
    );

export const getCourseDetailsList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourse/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getCourse - " + error.message)
            console.log('error caught in service : getCourse')
        },
    );
export const getReferralType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getReferralType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getReferralType - " + error.message)
            console.log('error caught in service : getReferralType')
        },
    );
export const getUserListByUserTypeId = (referralTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUserListByUserTypeId/${getLicenseKey}/${referralTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getUserListByUserTypeId - " + error.message)
            console.log('error caught in service : getUserListByUserTypeId')
        },
    );
export const getCourseCapacityByBatchCourse = (batchMasterId: any, courseId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseCapacityByBatchMasterId/${getLicenseKey}/${batchMasterId}/${courseId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseCapacityByBatchMasterId - " + error.message)
            console.log('error caught in service : getCourseCapacityByBatchMasterId')
        },
    );
export const getHolidayType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getHolidayType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getHolidayType - " + error.message)
            console.log('error caught in service : getHolidayType')
        },
    );

export const addHoliday = (holidayPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addHoliday`, holidayPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addHoliday')
        }
    );