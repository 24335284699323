import React, { useState } from 'react';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Button from '../../bootstrap/Button';
import AddLateAttendanceReason from '../../academic/attendance/AddLateAttendanceReason';
import FeeType from './FeeType';
import FineType from './FineType';
import StudentSchoolSubject from './StudentSchoolSubject';


const Module = () => {
	const moduleType = [
        { moduleTypeId: 1, moduleType: 'Fee Type' },
		{ moduleTypeId: 2, moduleType: 'Fine Type' },
		{ moduleTypeId: 3, moduleType: 'Student School Subject' },
		{ moduleTypeId: 4, moduleType: 'Late Attendance Reason' },
	];

	const addModuleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			modules: '',
		},
		validate: (values) => {
			const errors: {
				modules?: string;
			} = {};
			if (!values.modules) {
				errors.modules = 'Required';
			}
		},
		onSubmit: () => {},
	});

	const navigate = useNavigate();
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [moduleTypeId, setModuleTypeId] = useState<any>('');
    const [dataSuccess, setDataSuccess] = useState(false);

	const selectModuleType = (e: any) => {
        setDataSuccess(false);
		let moduleTypeId = e;
		setModuleTypeId(moduleTypeId);
	};

    const view =()=> {
        setDataSuccess(true);
        setIsOpenListCard(false);
    }

	return (
		<PageWrapper title='Module'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list' tag='form' noValidate>
						<CardHeader borderSize={1}>
							<CardLabel icon='list' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Module
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='moduleTypeId' label='Module Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Module Type'
											onChange={selectModuleType}
											value={moduleTypeId}
											list={moduleType.map((data: any) => ({
												value: data.moduleTypeId,
												label: data.moduleType,
											}))}
										/>
									</FormGroup>
								</div>
                                {moduleTypeId ?
                                <div className='col-md-2'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={view}>
											View
										</Button>
									</div>: null
                                }
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/> 
                {dataSuccess ? <>
                {moduleTypeId?.value == 1 ? 
                    <FeeType/> : moduleTypeId?.value == 2 ?
                    <FineType/> : moduleTypeId?.value == 3 ?
                    <StudentSchoolSubject/> : moduleTypeId?.value == 4 ?
                    <AddLateAttendanceReason />:  null
                }   </>: null
            }                     

			</Page>
		</PageWrapper>
	);
};

export default Module;
